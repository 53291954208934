import { GraphQLProvider, type GraphQLProviderInput, useGraphQLContext } from '@medely/ui-kit/web';
import { type DocumentNode } from 'graphql';
import { gql, type QueryOptions, useQuery } from '@apollo/client';
import React, { createContext, useContext, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useApolloClientContext } from './apolloClientContextProvider';
import { useCurrentUserStorage } from './authProvider';
import { type BaseContextArgs } from './utils';
import { useTokenStorage } from 'hooks/useTokenStorage';

const SCHEMA_PERMISSIONS_QUERY = gql`
  query GetAdminSchemaPermisionsForRequests {
    accountRequestPermissions
  }
`;
const STORAGE_KEY = 'schemaPermissions';

const schemaPermissionsStorage = {
  get: () => {
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEY));
    } catch (_) {
      return undefined;
    }
  },
  set: (permissions: unknown) => {
    if (isEmpty(permissions)) {
      return;
    }

    localStorage.setItem(STORAGE_KEY, JSON.stringify(permissions));
  },
  clear: () => localStorage.removeItem(STORAGE_KEY),
};

const useGraphQLPermissions = () => {
  const { currentUser } = useCurrentUserStorage();
  const { getToken } = useTokenStorage();
  const [token, setToken] = useState<string>(null);

  const {
    data: permissions,
    loading,
    refetch,
  } = useQuery(SCHEMA_PERMISSIONS_QUERY, { skip: !currentUser || !token });

  const refetchSchemaPermissions = async () => {
    setToken(await getToken());
    if (!permissions) {
      refetch();
    }
  };

  return {
    clear: schemaPermissionsStorage.clear,
    isLoading: loading,
    permissions: permissions?.accountRequestPermissions ?? schemaPermissionsStorage.get(),
    refetchSchemaPermissions,
  };
};

type GraphQLPermissionsContext = {
  clearSchemaPermissions: () => void;
  isLoading: boolean;
  permissions: unknown;
  refetchSchemaPermissions: () => Promise<void>;
};

const DEFAULT_CONTEXT: GraphQLPermissionsContext = {
  clearSchemaPermissions: schemaPermissionsStorage.clear,
  isLoading: true,
  permissions: undefined,
  refetchSchemaPermissions: () => Promise.resolve(),
};

const graphqlPermissionsContext = createContext<GraphQLPermissionsContext>(DEFAULT_CONTEXT);

export const GraphQLPermissionsContextProvider = ({ children }: BaseContextArgs): any => {
  const { isLoading, permissions, refetchSchemaPermissions } = useGraphQLPermissions();

  const graphQLProviderProps: Omit<GraphQLProviderInput, 'children'> = {
    enableTransform: true,
    permissionsTransformer: {
      onTransform: (message: string) => {
        const warning = `GraphQL Query transformed: ${message}`;
        console.warn(warning);
      },
      permissions,
    },
  };

  return (
    <graphqlPermissionsContext.Provider
      value={{ ...DEFAULT_CONTEXT, isLoading, permissions, refetchSchemaPermissions }}
    >
      <GraphQLProvider {...graphQLProviderProps}>{children}</GraphQLProvider>
    </graphqlPermissionsContext.Provider>
  );
};

export const useGraphQLPermissionsContext = (): GraphQLPermissionsContext =>
  useContext(graphqlPermissionsContext);

type GqlRequestInput = {
  query: string | DocumentNode;
  variables: unknown;
} & QueryOptions;

export const useGraphQLRequest = () => {
  const { client } = useApolloClientContext();
  const { transform } = useGraphQLContext();

  const gqlRequest = async (input: GqlRequestInput) => {
    if (input?.variables?.input?.search?.q?.length > 150) {
      throw new Error('Search limit exceeded 150 characters');
    }

    // Parse the ID to an integer in case the user passes it in the URL
    if (input?.variables?.input?.filter?.id) {
      input.variables.input.filter.id = parseInt(input.variables.input.filter.id);
    }

    const transformedQuery = transform(input.query);
    return client.query({
      ...input,
      query: transformedQuery,
    });
  };

  return { gqlRequest };
};
